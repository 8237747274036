<template>
	<dashboard-container
		toolbar
		title="Update admin"
		:subtitle="user.email ? `You are currently updating: ${user.email}` : null"
	>
		<section class="h-100">
			<!-- User Attributes form -->
			<div class="container px-0">
				<form
					class="my-4"
					autocomplete="off"
					@submit.prevent="updateUserAttributes"
				>

					<form-row title="Update admin attributes">	
						<!-- Clinic -->
						<div class="form-group col col-md-6 mr-50">
							<label for="updateUserClinic">
							Clinic <span class="text-danger">*</span>
							</label>

							<select
								class="custom-select"
								:class="getErrors[`updateUserClinic`] ? 'border-danger' : null"
								id="updateUserClinic"
								name="updateUserClinic"
								v-model="user.clinic"
							>
								<option selected disabled value="">Choose...</option>
								<option
									v-for="clinic in clinics"
									:key="clinic.id"
									:value="clinic.name"
								>{{ clinic.name }}</option>
							</select>

							<Alert
								error
								type="danger"
								v-show="getErrors[`updateUserClinic`]"
								:class="getErrors[`updateUserClinic`] ? 'invalid-error' : null"
							>
								<small>{{ getErrors[`updateUserClinic`] }}</small>
							</Alert>
						</div>

						<!-- Super admin -->
						<fieldset class="form-group w-100 col">
							<label for="updateUserSuper">
								Is this admin a super admin? <span class="text-danger">*</span>
							</label>
							
							<div ref="radio" class="form-row form-row__radio">
								<div
									v-for="(option, i) in adminOptions"
									:key="i"
									class="custom-control custom-radio col-12 col-md-3"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="updateUserSuper"
										:id="option.name"
										v-model="user.super_admin"
										:value="option.value"
									/>
									<label
										:for="option.name"
										class="custom-control-label custom-control-label__small"
									>
										<span class="w-100">{{ option.name }}</span>
									</label>
								</div>
							</div>
						</fieldset>
					</form-row>

					<button type="submit" class="btn btn-primary btn-block mt-n3 w-50">
						<div v-show="loading" class="spinner-border spinner-border-sm text-light" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<span :class="loading ? 'btn__loading' : null">Submit</span>
					</button>
				</form>

				<Alert
					v-if="user.updatedAttributes || user.attributesError"
					:type="user.updatedAttributes ? 'success' : 'danger'"
					class="mt-3 col-md-6"
				>
					<p v-if="user.updatedAttributes" class="mb-0"><strong>{{ user.email }}</strong> attributes have been updated</p>
					<p v-else class="mb-0">{{ user.attributesError }}</p>
				</Alert>
			</div>

			<!-- User password form -->
			<div class="container border-top mt-5 px-0">
				<form
					class="my-4"
					autocomplete="off"
					@submit.prevent="updateUserPassword"
				>
					<form-row title="Update admin password">
						<form-input
							block
							required
							password
							name="updateUserPassword"
							label="Password"
							v-model="user.password"
							description="Your password must be at least 8 characters long, contain upper and lower case letters, numbers and special characters"
						>
							<button
								@click="generatePassword"
								type="button"
								class="btn btn-sm btn-link px-0"
							>Generate a password</button>
						</form-input>
					</form-row>

					<button type="submit" class="btn btn-primary btn-block mt-n3 w-50">
						<div v-show="loading" class="spinner-border spinner-border-sm text-light" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<span :class="loading ? 'btn__loading' : null">Submit</span>
					</button>
				</form>

				<Alert
					v-if="user.updatedPassword || user.passwordError"
					:type="user.updatedPassword ? 'success' : 'danger'"
					class="mt-3 col-md-6"
				>
					<p v-if="user.updatedPassword" class="mb-0"><strong>{{ user.email }}</strong> password has been updated</p>
					<p v-else class="mb-0">{{ passwordError }}</p>
				</Alert>
			</div>
		</section>
	</dashboard-container>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Admin Components
import dashboardContainer from '@/components/admin/dashboard-container'

// Password Generator
import generatePassword from 'password-generator'

// Content Clinics JSON
import clinics from '@/inc/content/clinics'

// General Components
import formRow from '@/components/form/form-row'
import formInput from '@/components/form/form-input'
import Alert from '@/components/general/alert'

export default {
	components: {
		dashboardContainer,
		formRow,
		formInput,
		Alert
	},
	data() {
		return {
			loading: false,
			user: {
				data: null,
				email: '',
				super_admin: '',
				clinic: '',
				updatedAttributes: false,
				attributesError: '',
				password: '',
				passwordError: '',
				updatedPassword: false
			},
			clinics: clinics,
			adminOptions: [
				{
					name: 'Yes',
					value: 'true'
				}, {
					name: 'No',
					value: 'false'
				}
			]
		}
	},
	watch: {
		'user.super': {
			// Remove error
			handler: function (val) {
				if ( val && val.length ) delete this.getErrors['updateUserSuper']
			},
			deep: true
		},
		'user.clinic': {
			// Remove error
			handler: function (val) {
				if ( val && val.length ) delete this.getErrors['updateUserClinic']
			},
			deep: true
		},
		'user.password': {
			handler: function (val) {
				// Remove error
				if ( val && val.length && this.passwordStrength ) {
					delete this.getErrors['updateUserPassword']
				}
			},
			deep: true
		},
		'user.updatedPassword': {
			handler: function (oldValue, newValue) {
				// Remove alert
				if (oldValue != newValue) {
					setTimeout(() => {
						this.user.updatedPassword = false
					}, 1500)
				}
			},
			deep: true
		},
		'user.updatedAttributes': {
			handler: function (oldValue, newValue) {
				// Remove alert
				if (oldValue != newValue) {
					setTimeout(() => {
						this.user.updatedAttributes = false
					}, 1500)
				}
			},
			deep: true
    },
	},
	computed: {
		...mapGetters([
			'getErrors'
		]),
		passwordStrength() {
			/**
			 * Must satisfy all criteria:
			 * upper and lower case letters, numbers, special characters, at least 8 characters.
			 */
			const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#£$%^&*(),.?":{}|<>])(?=.{8,})');
			return strongRegex.test(this.user.password)
		}
	},
	methods: {
		logout() {
			setTimeout(() => {
				this.$store.dispatch( 'authLogout' ).then(() => {
					this.$router.push({
						path: this.$GLOBALS.PATH_LOGIN,
						query: {
							action: 'logoutSuccess'
						}
					})
				})
			}, 1000)
		},
		generatePassword() {
			// Clear Password
			this.user.password = ''

			// Generate password based on strength value
			const randomLength = Math.floor(Math.random() * (14 - 8)) + 8;
			while (!this.passwordStrength) {
				this.user.password = generatePassword(randomLength, false, /[\w\d?-]/);
			}
		},
		async updateUserAttributes() {
			// clear passwords error
			this.user.passwordError = ''
			delete this.getErrors['updateUserPassword']

			try {
				const response = await this.$api.patch(
					`${this.$GLOBALS.API_URL}/v1/user?username=${this.user.email}`, {
						'custom:clinic': this.user.clinic,
						'custom:super_admin': this.user.super_admin.toString()
					}
				)
				const data = await response.data
				if ( data ) {
					this.user.attributesError = ''
					this.user.updatedAttributes = true
					// Log user out to reset
					await this.logout()
				}
			} catch(error) {
				if (error.result) {
					// The request was made and the server responded
					this.user.attributesError = error.result.data['meta']['error']['error_message']
				} else if (error.request) {
					//The request was made but no response was received,
					let errorMessage = JSON.parse(error.request.response)
					errorMessage = errorMessage['meta']['error']['error_message']
					this.user.attributesError = errorMessage
				} else {
					// Something happened in setting up the request and triggered an Error
					this.user.attributesError = error.message
				}
			}
		},
		async updateUserPassword() {
			// clear attributes error
			this.user.attributesError = ''
			delete this.getErrors['updateUserClinic']
			delete this.getErrors['updateUserSuper']

			if ( this.user.password.length && this.passwordStrength ) {
				try {
					const response = await this.$api.patch(
						`/v1/user/password?username=${this.user.email}`, {
							password: this.user.password
						}
					)
					const data = await response.data
					if ( data ) {
						this.user.passwordError = ''
						this.user.updatedPassword = true
						// Log user out to reset
						await this.logout()
					}
				} catch(error) {
					if (error.result) {
						// The request was made and the server responded
						this.user.passwordError = error.result.data['meta']['error']['error_message']
					} else if (error.request) {
						//The request was made but no response was received,
						let errorMessage = JSON.parse(error.request.response)
						errorMessage = errorMessage['meta']['error']['error_message']
						this.user.passwordError = errorMessage
					} else {
						// Something happened in setting up the request and triggered an Error
						this.user.passwordError = error.message
					}
				}
			} else {
				await this.$store.commit('updateErrors', {
					...this.getErrors,
						updateUserPassword: 'Please enter a valid password'
				})
			}
		},
		async getUser() {
			this.loading = false

			try {
				const response = await this.$api.get(
					`/v1/user`, {
						params: {
							username: this.user.email
						}
					}
				)
				const data = await response.data.data

				// Set users data and remove loading
				this.user.data = data
				this.loading = false

			} catch(error) {
				this.loading = false

				if (error.result) {
					// The request was made and the server responded
					this.error = error.result.data['meta']['error']['error_message']
				} else if (error.request) {
					//The request was made but no response was received,
					let errorMessage = JSON.parse(error.request.response)
					errorMessage = errorMessage['meta']['error']['error_message']
					this.error = errorMessage
				} else {
					// Something happened in setting up the request and triggered an Error
					this.error = error.message
				}
			}
		},
		setUser() {
			if (this.user.data) {
				// Set which clinic user is from
				this.user.clinic = this.user.data.clinic

				if ( this.user.data.super_admin ) {
					this.user.super_admin = true
				} else {
					this.user.super_admin = false
				}
			}
		}
	},
	async mounted() {
		await this.getUser()
		await this.setUser()
	},
	created() {
		this.user.email = this.$route.params.id
	}
}
</script>